import React from "react";
import {useNavigate} from "react-router-dom";

const Reenviar = () => {
    const navigate = useNavigate();
        return(
            <>
                <button onClick={() => navigate("/email")} id="Reenviar">Reenviar!</button>
            </>

        )
    }
export default Reenviar