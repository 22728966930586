// /****************************************************
// Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
// Direito: CENPROS        End.: Av Cauaxi, 350
// Cidade: Barueri         Bairro: Alphaville
// Em: 14/03/2023          Versão: 1.0
// Aprovado por: Flávio Nunes
// ****************************************************

//Importando "React"
import React, {useState} from "react";
//Importando CSS da paginação
import "./confirmMembership.css"
//Importando axios para implementar requisição
import axios from "axios";
//Importando Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
//Importando Biblioteca para navegações
import {useLocation} from "react-router-dom";

//Criando Função
function ConfirmMembership({formData, navigate}) {

    //Declarando constantes de data
    const {state} = useLocation();
    const date = new Date();
    function getTxtMounth(date){
        switch (date) {
            case 0:
                return "Janeiro"
                break;
            case 1:
                return "Fevereiro"
                break;
            case 2:
                return "Março"
                break;
            case 3:
                return "Abril"
                break;
            case 4:
                return "Maio"
                break;
            case 5:
                return "Junho"
                break;
            case 6:
                return "Julho"
                break;
            case 7:
                return "Agosto"
                break;
            case 8:
                return "Setembro"
                break;
            case 9:
                return "Outubro"
                break;
            case 10:
                return "Novembro"
                break;
            case 11:
                return "Dezembro"
                break;
            default:
                console.log(`Sem valor para: ${date}.`);
        }
    }


    function getValue(valueMoney){
        switch (valueMoney){
            case "5":
                return "CINCO REAIS"
                break;
            case "10":
                return "DEZ REAIS"
                break;
            case "30":
                return "TRINTA REAIS"
                break;
            case "50":
                return "CINQUENTA REAIS"
                break;
            case "100":
                return "CEM REAIS"
                break;
            case "300":
                return "TREZENTOS REAIS"
                break;
            default:
                console.log(`Valor a ser doado: ${valueMoney}`)
        }
    }

    //Implementando constante de confirmação de envio de formulário
    const confirm = () => {
        axios.post(`http://${process.env.REACT_APP_LINK_API}/api/formulario`, state.data)
            .then(function (response) {
                navigate('/success')
            }).catch(function (error) {
            // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
            console.log(error);
        })
    }

    //Implementando mascara de CPF
    const maskCPF = (value) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})/, "$1-$2")
            .replace(/(-\d{2})\d+?$/, "$1");
    };
    //Implementando mascara SANEPAR
        const maskSanepar = (value) => {
            return value
                .replace(/(\d{7})(\d)/, "$1.$2")
        }
    //Retornando os dados para formar a página
    return (
        <>
            {/*Importando component Header*/}
            <Header/>
            <div className="container-confirm-donation">
                <div id="pdf-confirmar">
                    <h2>AUTORIZAÇÃO DE CONTRIBUIÇÃO NA CONTA DE ÁGUA DE VALOR A SER DOADO AO PROJETO JUQUINHA</h2>
                    <br/>
                    <p>EU, <b>{state.data.nome.toUpperCase()}</b>, RG N.° <b>{state.data.rg.toUpperCase()}</b>, CPF N°. <b>{maskCPF(state.data.cpf)}</b> RESIDENTE E DOMICILIADO <b>{state.data.logradouro.toUpperCase()}</b>, N.º <b>{state.data.numero.toUpperCase()}</b>, BAIRRO <b>{state.data.bairro.toUpperCase()}</b>,
                        NESTE MUNICÍPIO
                        DE PARAGOMINAS, ESTADO DO PARÁ, DECLARO SER O TITULAR DO REGISTRO N.º <b>{maskSanepar(state.data.numeroSanepar)}</b> DA SANEPAR, E
                        CONCORDO EXPRESSAMENTE EM DOAR O VALOR
                        DE <b>R$ {state.data.valor},00 ({getValue(state.data.valor)})</b> AO PROJETO JUQUINHA, A SER INCLUÍDO NA PRÓXIMA E FUTURAS CONTAS DE ÁGUA DE MINHA
                        TITULARIDADE, ATÉ QUE EU REVOGUE
                        EXPRESSAMENTE ESTA AUTORIZAÇÃO.
                    </p>
                    <h2>PARAGOMINAS, {date.getDate()} de {getTxtMounth(date.getMonth())} de {date.getFullYear()}</h2>
                    <p>
                        <b>(Após a confirmação, o documento acima será enviado para a SANEPAR
                            e uma cópia será enviada para seu e-mail cadastrado.)</b>
                    </p>
                </div>
            </div>
            {/*Botões de confirmação ou cancelamento de doação*/}
            <div id="buttons-confirm">
                <button className="button-hover" id="btn-cancel" onClick={() => navigate("/")}>Cancelar</button>
                <button className="button-hover" id="btn-confirm" onClick={confirm}>Confirmar</button>
            </div>
            <Footer/>
            {/*Component Footer*/}
        </>
    )
}

export default ConfirmMembership
