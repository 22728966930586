import logo1 from "../img/JuquinhaLogo.png";
import banner from "../img/Foto_juquinha.png";
import React from "react";

const Header = () => {
    return (
        <header id="desktop">
            <table id="desktop-header">
                <tr>
                    <td id="titulo-header">
                        <p>
                            <h2>Projeto</h2>
                            <h1>JUQUINHA</h1>
                        </p>
                        <img id="logo" src={logo1} alt="Juquinha Projeto"/>
                    </td>
                    <td id="banner">
                        <img src={banner} alt="Doe Agora"/>
                    </td>
                </tr>
            </table>
            <table id="mobile-header">
                <tr>
                    <td id="mobile-text">

                        <h2>Projeto</h2>
                        <h1>JUQUINHA</h1>

                    </td>
                    <td id="mobile-image">
                        <img src={logo1} alt="Juquinha Projeto"/>
                    </td>
                </tr>
            </table>

        </header>
    )
}

export default Header