// /****************************************************
// Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
// Direito: CENPROS        End.: Av Cauaxi, 350
// Cidade: Barueri         Bairro: Alphaville
// Em: 14/03/2023          Versão: 1.0
// Aprovado por: Flávio Nunes
// ****************************************************

//Importando "React"
import React from 'react';
//Importando CSS da paginação
import './index.css';
//Importando Components
import App from './App';
//Importando Bibliotecas
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";

//Implementando constante para rota principal sempre!
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
);

