// /****************************************************
// Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
// Direito: CENPROS        End.: Av Cauaxi, 350
// Cidade: Barueri         Bairro: Alphaville
// Em: 14/03/2023          Versão: 1.0
// Aprovado por: Flávio Nunes
// ****************************************************

//Importando "React"
import React, {useState} from "react";
//Importando CSS da paginação
import "./requestToken.css";
//Importando Components
import Footer from "../../components/Footer";
import Header from "../../components/Header";
//Importando axios para implementar requisição
import axios from 'axios'

//Criando Função
function RequestToken({email, setEmail, token, setToken, navigate}) {

    //REQUISIÇÃO com variavel de ambiente
    const [error, setError] = useState(null);
    const requisicao = `http://${process.env.REACT_APP_LINK_API}/api/validacao?email=${email}`

    //Requisição para solicitação de token após encaminhar o email
    function solicitaToken(email) {
        email.preventDefault();
        axios.get(requisicao)
            .then(function (response) {
                // aqui acessamos o corpo da resposta:
                // console.log(response.data);

                setToken(response.data.token)
                // let mail = document.getElementById("input-email").value
                // let data = `${response.data.token}${mail}`
                // navigate('/validacao', {state: {data: data}});
                navigate('/validacao');
            })
            .catch(function (error) {
                // aqui temos acesso ao erro, quando alguma coisa inesperada acontece:
                console.log(error);
            })
    }

    //Armazenando email
    const handleChange = (event) => {
        setEmail(event.target.value);
    };
    //Sleep time para carregamento de components
    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    //Focus no primeiro bloco, no caso o input
    const focus = () => {
        sleep(5).then(() => {
            document.getElementById("input-email").focus()
        });
    }

    //Retornando os dados para formar a página
    return (
        <div id="div-email">
            <Header/>
            {/*Componet Header*/}
            <br/>
            <br/>
            {focus()}
            {/*Implementação do focus para input de email*/}
            <div className="form-box">
                <h2>Digite o seu Email</h2>
                {
                    error &&
                    (<p id="texto-erro">{error}</p>)
                }
                {/*Fazendo o requisição do token após o click*/}
                <form onSubmit={solicitaToken}>
                    <div className="data-box">
                        <input className="campo-email" type="email" value={email}
                               onChange={handleChange}
                               id="input-email" name="input-email" maxLength="82" required/>
                        <label htmlFor="input-email">Email</label>
                    </div>
                    <div>
                        <button className="button-hover" style={{margin: " 0 25%"}}>Solicitar Código</button>
                    </div>
                </form>
            </div>
            <Footer/>
            {/*Component Footer*/}
        </div>
    );
}

export default RequestToken;
//Fim do export