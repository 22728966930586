import React from "react";
import {useNavigate} from "react-router-dom";

const Back = ({navigate}) => {

    return(
        <div>
            <button onClick={() => navigate("/")} type="button" id="button-back">Voltar</button>
        </div>
    )
}
export default Back