import React from "react";


const Conta = ({numero, banco, agencia, conta, cnpj}) => {

    return (
        <>
            <tr>
                <td rowSpan={4} className="td-id">
                    Opção {numero}
                </td>
                <td id="agency-back">
                    Banco: {banco}
                </td>
            </tr>
            <tr>
                <td>
                    Agencia: {agencia}
                </td>
            </tr>
            <tr>
                <td>
                    Conta: {conta}
                </td>
            </tr>
            <tr>
                <td>
                    Cnpj: {cnpj}
                </td>
            </tr>
            <br/>
        </>
    )
}
const Contas = ({numero, banco, agencia, conta, cnpj}) => {

    const contas = [
        {numero: 1, banco: "Bradesco", agencia: "0679-3", conta: "21012-9", cnpj: "06.064.766/0001-16"},
        {numero: 2, banco: "Sicredi", agencia: "0804", conta: "07418-2", cnpj: "06.064.766/0001-16"},
        {numero: 3, banco: "Banco do Brasil", agencia: "0820-6", conta: "18.990-1", cnpj: "06.064.766/0001-16"},
        {numero: 4, banco: "Caixa Econômica Federal", agencia: "3192", conta: "1.709-2", cnpj: "06.064.766/0001-16"}
    ]

    return (
        <div className="option" id="contas">
            <h1 className="option-title">Contas</h1>
            <table>
                {
                    contas.map(
                        (conta) => (
                            <Conta numero={conta.numero} banco={conta.banco}
                                   agencia={conta.agencia} conta={conta.conta}
                                   cnpj={conta.cnpj}/>
                        )
                    )
                }
            </table>

        </div>
    )
}

export default Contas