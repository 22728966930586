
//Importando React
import React, {useState} from "react";
//Importando components
import InitialPage from "./pages/initialPage";
import EmailPage from "./pages/putToken";
import FormPage from "./pages/formPage";
import EndPage from "./pages/endPage";
import RequestToken from "./pages/requestToken";
import ConfirmAdesao from "./pages/confirmMembership";
import ReactGA from "react-ga"
//Importando bibliotecas de rotas
import {Routes, Route, useNavigate} from "react-router-dom";

const TRACKING_ID = "G-CHXRQNV9X4";
ReactGA.initialize(TRACKING_ID);

//Criando Função com o seu fim ja indicado
export default function App(){

    //Criando constantes e navegações
    const [email, setEmail] = useState("")
    const [token, setToken] = useState("")
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    //Retornando os dados para formar a página
    return(
        //Implementando Biblioteca de rotas com as constantes indicadas
        
        <div className="Routes">
            <Routes>
                <Route path="/" element={ <InitialPage navigate={navigate}/> } />
                <Route path="/email" element={<RequestToken email={email} setEmail={setEmail} navigate={navigate}
                                                     token={token} setToken={setToken} />}/>
                <Route path="/validacao" element={ <EmailPage token={token} navigate={navigate}/>} />
                <Route path="/form" element={ <FormPage email={email} setFormData={setFormData} navigate={navigate}/> } />
                <Route path="/confirm" element={<ConfirmAdesao formData={formData} navigate={navigate}/>} />
                <Route path="/success" element={ <EndPage navigate={navigate}/> } />
            </Routes>
        </div>
    );
}
