import QrCode from "../img/QrCode.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import React from "react";

const Pix = () => {
    function PixCopiar() {
        alert("Pix copiado para a área de tranferência!");
    }

    return (
        <div className="option" id="pix">
            <h1 className="option-title">Pix</h1>

            <div className="button-type">
                <img id="QrCode-Image" src={QrCode}/>
                <p>Chave: 06.064.766/0001-16 </p>
                <p>Associação José Pereira de Farias</p>
                <CopyToClipboard text={'06.064.766/0001-16'}>
                    <button onClick={PixCopiar} id="donation-pix">COPIAR CHAVE PIX</button>
                </CopyToClipboard>
            </div>
        </div>
    )
}

export default Pix