import React from "react";

const DoacaoMensal = ({navigate}) => {

    return (
        <div className="option" id="doacao-mensal">
            <h1 className="option-title">Doação Mensal</h1>
            <h3>Como funciona?</h3>
            <div className="container-description">
                <a>
                    Preencha corretamente seu e-mail e aguarde o código de confirmação contendo 6 dígitos.
                    Após essa etapa será necessário realizar o cadastro com suas informações pessoais e é importante
                    que não se esqueça de informar o número de inscrição da conta SANEPAR.
                    Feito isso, a autorização de desconto mensal já estará aplicada e as crianças agradecerão sua doação!
                    Canal de comunicação para clientes SANEPAR (Agência de saneamento Paragominas)
                </a>
            </div>
            <button onClick={() => navigate("/email")} id="button-donation">Doar</button>
        </div>
    )
}

export default DoacaoMensal