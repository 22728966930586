import React from "react";
import image from "../img/foto_final1.jpg";
import Back from "./backbutton";
import Footer from "./Footer";

const BackgroundDonationThanks = ({navigate}) => {
    return(
        <div style={{ backgroundImage: `url(${image})`,width:"100vw",height:"100vh"}}>
            <div id="content">
            <p>Muito obrigado pela sua doação!
            <br/>
            <br/>
                As crianças do Projeto Juquinha agradecem!
                <br/>
                <br/>
                <Back navigate={navigate}/>
            </p>
            <Footer/>
        </div>
        </div>

    )
}
export default BackgroundDonationThanks