import Instagram from "../img/instagram.png";
import Facebook from "../img/facebook.png";
import React from "react";


const Footer = () => {
    return(
        <table className="footer">
            <tr className="social-media">
                <a href="https://www.instagram.com/projetojuquinha/">
                    <img id="social-media" src={Instagram} />
                </a>
                <a href="https://www.facebook.com/juquinha.pereiradefarias">
                    <img id="social-media-Facebook" src={Facebook} />
                </a>
            </tr>
        </table>
    )
}

export default Footer