// /****************************************************
// Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
// Direito: CENPROS        End.: Av Cauaxi, 350
// Cidade: Barueri         Bairro: Alphaville
// Em: 14/03/2023          Versão: 1.0
// Aprovado por: Flávio Nunes
// ****************************************************

//Importando "React"
import React from "react";
//Importando CSS da paginação
import "./initialPage.css";
//Importando Components
import Contas from "../../components/Contas";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import DoacaoMensal from "../../components/DoacaoMensal";
import Pix from "../../components/Pix";
import { useEffect } from "react";
import ReactGA from "react-ga"

/* eslint-disable */
//Criando Função
function InitialPage({navigate}) {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname)
    }, []);

    //Constante de contas para caso houver outras contas, apenas adicionar
    const contas = [
        {numero: 1, banco: "Bradesco", agencia: "0679-3", conta: "21012-9", cnpj: "06.064.766/0001-16"},
        {numero: 2, banco: "Sicredi", agencia: "0804", conta: "07418-2", cnpj: "06.064.766/0001-16"},
        {numero: 3, banco: "Banco do Brasil", agencia: "0820-6", conta: "18.990-1", cnpj: "06.064.766/0001-16"},
        {numero: 4, banco: "Caixa Econômica Federal", agencia: "3192", conta: "1.709-2", cnpj: "06.064.766/0001-16"}
    ]
    //Function para copiar Pix para área de trabalho e alertar o usuário
    function PixCopiar() {
        alert("Pix copiado para a área de tranferência!");
    }
    //Retornando os dados para formar a página
    return (
        <div className="container">
            {/*Component Header*/}
            <Header />
            <div className="formas">
                {/*Componets iniciais*/}
                <DoacaoMensal navigate={navigate}/>
                <Contas/>
                <Pix/>
            </div>
            {/*Component Footer*/}
            <Footer/>
        </div>
    );
}

//Fim do export
export default InitialPage;
