// /****************************************************
// Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
// Direito: CENPROS        End.: Av Cauaxi, 350
// Cidade: Barueri         Bairro: Alphaville
// Em: 14/03/2023          Versão: 1.0
// Aprovado por: Flávio Nunes
// ****************************************************

//Importando "React"
import React, {useState} from "react";
//Importando CSS da paginação
import "./putToken.css";
//Importando Components
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Reenviar from "../../components/ReenviarEmail";

//Criando Função
function PutToken({token, navigate}) {
    //Constante

    const changeFirstInput = (inputValue) =>{
        if (inputValue === token)
            navigate('/form')

        if (inputValue[0] !== undefined)
            document.getElementById("number1").value = inputValue[0]

        selectNumber("number2")
    }


    const selectNumber = (id) => {
        document.getElementById(id).focus()
    }

    const getValue = (id) => {
        return document.getElementById(id).value
    }

    const verificaToken = () => {

        let tokenInformado = ""
        for (let num = 1; num <= 6; num++) {
            tokenInformado += getValue("number" + num)
        }

        if (tokenInformado.length !== 6)
            return

        // console.log(tokenInformado)
        // let token = data.substring(0, 6)
        // let email = data.substring(6)

        if (tokenInformado === token) {
            navigate('/form');
            // navigate('/form', {state: {email}});
        } else {
            alert("Token Inválido")
        }
    }

    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const [focusMaked, setFocusMaked] = useState(false)

    const focus = () => {
        sleep(5).then(() => {
            console.log(!focusMaked)

            if (!focusMaked) {
                document.getElementById("number1").focus()
                setFocusMaked(true)
            }
        });
    }

    //Retornando os dados para formar a página
    return (
        <>
            <Header/>
            {focus()}
            <div className="game">
                <div className="letterContainer">
                    <h1>Coloque o código encaminhado em seu email</h1>
                    <form className="form-code">
                        <input type="text" id="number1" onChange={(e) => changeFirstInput(e.target.value)} maxLength="6"
                               required/>
                        <input type="text" id="number2" onChange={() => selectNumber("number3")} maxLength="1"
                               required/>
                        <input type="text" id="number3" onChange={() => selectNumber("number4")} maxLength="1"
                               required/>
                        <input type="text" id="number4" onChange={() => selectNumber("number5")} maxLength="1"
                               required/>
                        <input type="text" id="number5" onChange={() => selectNumber("number6")} maxLength="1"
                               required/>
                        <input type="text" id="number6" onChange={verificaToken} maxLength="1" required/>
                        <br/>
                        <br/>
                        <br/>
                        <button id="Reenviar" onClick={verificaToken}>Verificar</button>
                        <Reenviar/>
                        {/*<button id="Reenviar" onClick={() => solicitaToken(email)}>Reenviar!</button>*/}
                    </form>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default PutToken;
//Fim do export