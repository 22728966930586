 // /****************************************************
 // Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
 // Direito: CENPROS        End.: Av Cauaxi, 350
 // Cidade: Barueri         Bairro: Alphaville
 // Em: 14/03/2023          Versão: 1.0
 // Aprovado por: Flávio Nunes
 // ****************************************************

//Importando "React"
import React, {useState} from "react";
//Importando CSS da paginação
import "./formPage.css";
//Importando Components
import Footer from "../../components/Footer";
//Importando bibliotecas
import {IMaskInput} from "react-imask";

//Criando Função
function FormPage({email, setFormData, navigate}) {

    //Declarando Constantes
    const [error, setError] = useState(null);
    const [nome, setNome] = useState("")
    const [rg, setRg] = useState("")
    const [cpf, setCpf] = useState("")
    const [cep, setCep] = useState("")
    const [cidade, setCidade] = useState("")
    const [uf, setUf] = useState("");
    const [endereco, setEndereco] = useState("")
    const [bairro, setBairro] = useState("")
    const [numero, setNumero] = useState("")
    const [complemento, setComplemento] = useState("")
    const [numeroSanepar, setNumeroSanepar] = useState("")
    const [valor, setValor] = useState("")
    const [dataNasc, setdataNascimento] = useState("")
    const [confirmacao, setConfirmacao] = useState()

    //Verificar se possue email para forms
    if (!email) {
        navigate('/')
    }
    //Mostrar PDF
    const showPdf = (show) => {
        let termos = document.getElementById("termos-pdf")
        termos.style.display = show ? "block" : "none"
    }

    //API de busca de CEP via number
    const buscarCep = async (cepRecebido) => {
        setCep(cepRecebido)
        if (validaCep(cepRecebido)) {
            let url = `https://viacep.com.br/ws/${cepRecebido}/json/`

            // const resp = await fetch(url, {mode:'no-cors'})
            const resp = await fetch(url, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'aplication/json'
                }
            })
            const data = await resp.json()

            setEndereco(data.logradouro)
            setBairro(data.bairro)
            setCidade(data.localidade)
            setUf(data.uf)
            document.getElementById("input-number").focus()
        }
    }
    //Validacao do CEP digitado
    const validaCep = (cepRecebido) => {
        cepRecebido = cepRecebido.replace(/[^0-9]/gi, "");
        return cepRecebido.length === 8
    }
    //Validacao do RG digitado
    // const validaRg = (rg) => {
    //     return parseInt(rg).toString().length >= 8
    // }
    //Validando CPF com formato ("000.000.000-00")
    const validaCpf = (cpfRecebido) => {

        cpfRecebido = cpfRecebido.replace(/[^0-9]/gi, "");

        if (cpfRecebido.length !== 11 || ['00000000000', '11111111111', '22222222222',
            '33333333333', '44444444444', '55555555555', '66666666666',
            '77777777777', '88888888888', '99999999999'].includes(cpfRecebido)) {
            return false;
        }

        let soma = 0;
        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpfRecebido.charAt(i)) * (10 - i);
        }
        let resto = 11 - (soma % 11);
        if (resto === 10 || resto === 11) {
            resto = 0;
        }
        if (resto !== parseInt(cpfRecebido.charAt(9))) {
            return false;
        }
        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpfRecebido.charAt(i)) * (11 - i);
        }
        resto = 11 - (soma % 11);
        if (resto === 10 || resto === 11) {
            resto = 0;
        }
        if (resto !== parseInt(cpfRecebido.charAt(10))) {
            return false;
        }
        return true;
    }

    //Garantindo que todos os campos estão preenchidos , caso não preenchidos, notificação de erro
    const handleSubmit = (e) => {
        e.preventDefault()
        //Valida CPF
        if (!validaCpf(cpf)) {
            setError("Cpf Inválido!")
            document.getElementById("input-cpf").focus()
            console.log(error)
            return
        }
        //Valida CEP
        if (!validaCep(cep)) {
            setError("Cep Inválido!")
            document.getElementById("input-cep").focus()
            console.log(error)
            return
        }
        //Valida SANEPAR
        if (!validSanepar(numeroSanepar)){
            setError("Número SANEPAR inválido!")
            document.getElementById("input-account").focus()
            console.log(error)
            return
        }
        //Valida Valor
        if (valor === "" || valor === "unchosed") {
            setError("Escolha um valor!")
            console.log(error)
            return
        }
        setError(null)
        console.log("Ok")

        //Retornando os dados sem o (".") para o banco de dados
        const data = {
            nome: nome,
            rg: rg,
            cpf: cpf.replaceAll(".", "").replaceAll("-", ""),
            email: email,
            dataNasc: dataNasc.toString(),
            cep: cep,
            cidade: cidade,
            uf: uf,
            logradouro: endereco,
            bairro: bairro,
            numero: numero,
            complemento: complemento,
            valor: valor,
            numeroSanepar: numeroSanepar.replaceAll(".", "")

        }
        navigate('/confirm', {state: {"data":data}});
    }

    //Mascara de CPF ("000.000.000-00")
    const maskCPF = (value) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})/, "$1-$2")
            .replace(/(-\d{2})\d+?$/, "$1");
    };

    //Mascara de data
    const maskDate = (value) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "$1/$2")
            .replace(/(-\d{5})\d+?$/, "$1/")
            .replace(/(\d{2})(\d)/, "$1/$2");
    }

    //Mascara para digito SANEPAR ("0000000.0")
    const maskSanepar = (value) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{7})(\d)/, "$1.$2")
            .replace(/(-\d{2})\d+?$/, "$1");
    };
    //Validando numero SANEPAR
    const validSanepar = (value) => {
        return value.replace(".","").length === 8
    }

    //Promise de time para recarregamento dos componentes
    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }


    //Focus ao entrar em qualquer página para local da primeira digitação
    const [focusMaked, setFocusMaked] = useState(false)
    const focus = () => {
        sleep(50).then(() => {
            if (!focusMaked) {
                document.getElementById("input-name").focus()
                setFocusMaked(true)
            }
        });
    }

    function valDate(date) {
        let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])/;

        // Matching the date through regular expression
        if (date.match(dateformat)) {
            let operator = date.split('/');

            // Extract the string into month, date and year
            let datepart = [];
            if (operator.length > 1) {
                datepart = date.split('/');
            }
            let day = parseInt(datepart[0]);
            let month = parseInt(datepart[1]);
            let year = parseInt(datepart[2]);

            // Create a list of days of a month
            let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (month === 1 || month > 2) {
                if (day > ListofDays[month - 1]) {
                    //to check if the date is out of range
                    console.log("Invalid date")
                    return false;
                }
            } else if (month === 2) {
                let leapYear = false;
                if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
                if ((leapYear === false) && (day >= 29)) {
                    console.log("Invalid date")
                    return false;
                }
                else
                if ((leapYear === true) && (day > 29)) {
                    console.log('Invalid date format!');
                    return false;
                }
            }
        } else {
            console.log("Invalid date format!");
            return false;
        }
        return "Valid date";
    }
    //Retornando os dados para formar a página
    return (
        <div id="id-form">
            {focus()}
            <div className="form-box" id="form-data">
                <h2>Fomulário de Dados</h2>
                <form onSubmit={handleSubmit}>
                    <div className="data-box">
                        <input className="campo-nome" type="text" value={nome}
                               onChange={(e) => setNome(e.target.value)}
                               id="input-name" name="input-nome" maxLength="82" required/>
                        <label htmlFor="input-name">Nome</label>
                    </div>
                    <div className="data-box">
                        <IMaskInput className="campo-rg" type="text" value={rg}
                                    onChange={(e) => setRg(e.target.value)}
                                    id="input-rg" name='input-rg' maxLength="9" required/>
                        <label htmlFor="input-rg">RG</label>
                    </div>
                    <div className="data-box">
                        <IMaskInput className="campo-cpf" type="text" value={cpf}
                                    onChange={(e) => setCpf(maskCPF(e.target.value))}
                                    id="input-cpf" name='input-cpf' maxLength="14" required/>
                        <label htmlFor="input-cpf">CPF</label>
                    </div>
                    <div className="data-box">
                        <input className="campo-cep" type="number" value={cep}
                               onChange={(e) => buscarCep(e.target.value)}
                               id="input-cep" name="input-cep" maxLength="8" required/>
                        <label htmlFor="input-cep">CEP</label>
                    </div>
                    <div className="data-box">
                        <input className="campo-endereco" type="text" value={endereco}
                               onChange={(e) => setEndereco(e.target.value)} maxLength="100"
                               id="input-endereco" name="input-endereco" required/>
                        <label htmlFor="input-endereco">Endereço</label>
                    </div>
                    <div className="data-box">
                        <input className="campo-bairro" type="text" value={bairro}
                               onChange={(e) => setBairro(e.target.value)} maxLength="45"
                               id="input-bairro" name="input-bairro" required/>
                        <label htmlFor="input-bairro">Bairro</label>
                    </div>
                    <div className="data-box">
                        <input className="campo-numero" type="text" value={numero}
                               onChange={(e) => setNumero(e.target.value)}
                               id="input-number" name='input-number' maxLength="12" required/>
                        <label htmlFor="input-number">Número</label>
                    </div>
                    <div className="data-box">
                        <input className="campo-complemento" type="text" value={complemento}
                               onChange={(e) => setComplemento(e.target.value)}
                               id="input-complemento" name='input-complemento' maxLength="80"/>
                        <label htmlFor="input-complemento">Complemento</label>
                    </div>
                    <div className="data-box">
                        <IMaskInput className="campo-number-account" type="text" maxLength="9" value={numeroSanepar}
                                    onChange={(e) => setNumeroSanepar(maskSanepar(e.target.value))}
                                    id="input-account" name='input-account' required/>
                        <label htmlFor="input-account">Número do registro SANEPAR</label>
                    </div>
                    <div className="data-box">
                        <input className="campo-data-nascimento" type="date" onKeyUp={valDate} max="2050-12-31"
                                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                    value={dataNasc}
                                    onChange={(e) => setdataNascimento(e.target.value)}
                                    id="input-date" name='input-date' required/>
                        <label htmlFor="input-date">Data de nascimento</label>
                    </div>
                    <div className="chose-value">
                        <label id="chose-value">
                            Valor de doação mensal &nbsp;
                            <select name="input-value" id="input-value" value={valor}
                                    onChange={(e) => {
                                        setValor(e.target.value)
                                    }}>
                                <option value="unchosed" selected>Escolha o valor</option>
                                <option value="5">R$ 5</option>
                                <option value="10">R$ 10</option>
                                <option value="30">R$ 30</option>
                                <option value="50">R$ 50</option>
                                <option value="100">R$ 100</option>
                                <option value="300">R$ 300</option>
                            </select>
                        </label>
                    </div>
                    <div className="valor-termos">
                        <input type="checkbox" id="autorization" name="autorization"
                               value={confirmacao} onChange={(e) => setConfirmacao(e.target.value)}
                               required/>
                        <p id="link-termos">
                            <b>Atesto que as informações preenchidas acima são verdadeiras.</b>
                        </p>
                        <div id="termos-pdf">
                            <span id="close-pdf" title="Fechar" onClick={() => showPdf(false)}>X</span>
                            <object data="./Autorização%20Sanepar.pdf" type="application/pdf">
                                <img src="/PDFJuquinha.png" alt="Imagem de autorização"/>
                            </object>
                        </div>

                    </div>
                    {
                        error &&
                        (<p id="texto-erro">{error}</p>)
                    }
                    <button className="button-hover" onClick={() => setError()} style={{margin: " 0 25%", padding: "1% "}}>Confirmar</button>
                </form>
                {/*Fim do Forms*/}
            </div>
            <Footer/>
            {/*Component Footer*/}
        </div>
    );
}
export default FormPage;
//Fim do export

