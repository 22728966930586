// /****************************************************
// Desenvolvido por: Gabriel Dos Santos Rezende, Kaique Da Silva Mendonça, Vitor Lopes Rodrigues
// Direito: CENPROS        End.: Av Cauaxi, 350
// Cidade: Barueri         Bairro: Alphaville
// Em: 14/03/2023          Versão: 1.0
// Aprovado por: Flávio Nunes
// ****************************************************

//Importando "React"
import React from "react";
//Importando CSS da paginação
import "./endPage.css"
//Importando bibliotecas
import styled, {keyframes} from 'styled-components';
import {fadeIn} from 'react-animations'
//Importando Components
import BackgroundDonationThanks from "../../components/ImageBackgroundSucces";

//Constante para iniciar animação
const FadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 6s ${FadeInAnimation};
`;

//Criando Função
function EndPage({navigate}) {
    //Retornando os dados para formar a página
    return (
        <>
            {/*Começando animação*/}
            <FadeInDiv>
                {/*Component anexado com navegação*/}
                <BackgroundDonationThanks navigate={navigate}/>
            </FadeInDiv>
            {/*Terminando animação*/}
            {/*Lembre-se, todo texto ou imagem que estiver dentro da animação "FadeIn" estara*/}
            {/*com o estilo indicado*/}
        </>
    );
}

export default EndPage;
//Fim do export